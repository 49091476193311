// Purpose: Set the root font size for the document based on a data attribute on the body element
// For new tailwind css based pages, we want to set the root font size to 16px (which is normaly the default)
// But for legacy pages, we want to set the root font size to 10px to match the legacy css
//
// To set the font-size, set @rem_size = "16px" in the controller's view file
document.addEventListener("turbo:load", function() {
  let htmlElement = document.documentElement;
  let bodyElement = document.body;

  htmlElement.style.fontSize = bodyElement.dataset.remSize || "10px"; // default to 10px for all legacy pages
});

