import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="voucher-resource"
export default class extends Controller {
  static values = {view: String, projectId: String};
  static targets = ['projectIdSelectInput', 'entitlementSelectInput', 'entitlementSelectWrapper'];

  get projectIdFromSelect() {
    return this.hasProjectIdSelectInputTarget &&  this.projectIdSelectInputTarget.value;
  }

  initialize() {
    if (this.viewValue === "new" && this.hasProjectIdSelectInputTarget) {
      this.prepareEntitlementSelectOptions();
      this.projectIdValue = this.projectIdFromSelect;
    }
  }

  prepareEntitlementSelectOptions() {
    // convert <option value="1">Entitlement 1 [1]</option>
    // to
    // <option value="1" data-project-id="1">Entitlement 1</option>
    Array.from(this.entitlementSelectInputTarget.options).forEach((option) => {
      if (option.value) {
        const match = option.text.match(/\[(.*?)\]$/);
        if (match) {
          const projectId = match[1];
          option.text = option.text.replace(/\[.*?\]$/, '');;
          option.dataset.projectId = projectId;
        }
      }
    });
  }

  disableEntitlementSelect() {
    this.entitlementSelectInputTarget.disabled = true;
    this.entitlementSelectInputTarget.selectedIndex = 0;
  }

  showEntitlementSelectOptionsForProject(projectId) {
    Array.from(this.entitlementSelectInputTarget.options).forEach((option) => {
      if (option.value) {
        if (option.dataset.projectId === projectId) {
          option.hidden = false;
        } else {
          option.hidden = true;
        }
      }
    });
  }

  projectIdValueChanged() {
    if (this.viewValue === "new") {
      if (this.projectIdValue) {
        this.entitlementSelectInputTarget.disabled = false;
        this.showEntitlementSelectOptionsForProject(this.projectIdValue);
      } else {
        this.disableEntitlementSelect();
      }
    }
  }

  onProjectChange(event) {
    if (this.projectIdFromSelect) {
      this.projectIdValue = this.projectIdSelectInputTarget.value;
    }
  }
}
